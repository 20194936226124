@import "../../variables";

.vehicle-details {
  border-bottom: 1px dashed $gray-300;

  .notice-type {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .notice-icon {
    font-size: 2.5rem;

    &::before {
      vertical-align: middle;
    }
  }
}

.section {
  margin-top: 1.5rem;
  &-title {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    border-left: 5px solid $carlocate-red;
    border-bottom: 1px solid $carlocate-red;
  }
}
