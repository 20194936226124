@import "../../variables";

@media print {
  @page {
    size: 8.5in 11in portrait;
  }
}

.header {
  .print-button {
    border: none;
    background: #ddd;
    box-shadow: none;

    @media print {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .logo {
    max-width: 300px;
    height: auto;

    img {
      width: 100%;
    }
  }

  .header-map {
    overflow: hidden;
    height: 175px;
    margin: -120px -15px 0;

    @media print {
      height: 105px;
      margin: -60px -15px 0;
    }

    @include media-breakpoint-down(sm) {
      height: 105px;
      margin: -60px -15px 0;
    }

    .map {
      background-image: url("../../images/header-map.png");
      height: 165px;
      -webkit-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.75);

      @media print {
        background-image: url("../../images/header-map.png");
        -webkit-print-color-adjust: exact;
        height: 95px;
      }

      @include media-breakpoint-down(sm) {
        height: 95px;
      }
    }
  }

  .map-pin {
    margin-top: -85px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 140px;
    height: auto;

    @media print {
      margin-top: -80px;
      margin-left: auto !important;
      margin-right: 0 !important;
      width: 95px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: -80px;
      margin-left: auto;
      margin-right: 0;
      width: 95px;
    }
  }
}
