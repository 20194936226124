@import "../../variables";

.homepage {
  overflow-x: hidden;

  .background {
    background-image: url("./images/background.png");
    background-size: cover;
    background-position: center;
    min-height: 69vh;

    button {
      background: #277fa5;
      box-shadow: none;
      border: none;
    }

    .title {
      color: $dark;
    }

  }

  .callToAction {
    padding: 2em 0 1.5em 0;
    vertical-align: middle;
    text-align: center;
  }

  .logo {

    @media (max-width: 400px) {
      margin-top: none;
      max-height: 100%;
    }

    margin-top: 8em;
    width: 400px;
    height: auto;
    max-height: 40%;

    @include media-breakpoint-down(sm) {
      width: 250px;
      height: auto;
    }
  }
}