@import "../../variables";

.display-field-title {
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 5px;

  & + div {
    text-transform: uppercase;
  }

}
