@import "../../variables";

.footer {
  font-size: 13px;

  .text-center {
    margin-top: 3em;
  }

  .footer-logo {
    width: 200px;
    height: auto;

    @include media-breakpoint-down(sm) {
      width: 150px;
      height: auto;
    }
  }
}
