@import "../../variables";

html,
body,
#root {
  height: 100%;
}

.background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media print {
    display: none;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.page-wrapper {
  position: relative;
  min-height: 100%;
  background-color: $white;

  .content {
    padding-bottom: 150px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 275px;
    }
  }
}

.page-footer {
  position: relative;
  margin-top: -150px;
  clear: both;
  padding-top: 20px;

  @include media-breakpoint-down(sm) {
    margin-top: -275px;
  }
}
