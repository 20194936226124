@import "../../variables";

.loading-text {
  text-transform: uppercase;
  font-weight: 600;
  animation: gradient-flow 10s ease-in-out infinite;
  background: linear-gradient(60deg, $black, $white, $carlocate-red);
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.arrow-circle-down {
  display: block;
  width: 40px;
  height: 40px;
  margin: 2rem auto 0;
  fill: $carlocate-red;
}

.bounce {
  -webkit-animation: bounce 2s;
  animation: bounce 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes gradient-flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}
