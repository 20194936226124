@import "../../variables";

.search-box {
  background-color: $white;

  padding: 1rem;
  border-radius: 5px;
  height: auto;
  box-shadow: 0 0 5px $dark;

  .privacyTerms > p {
    margin-bottom: 0px;
  }
}

.confirm-vin {
  margin-left: 20px;
  cursor: pointer;
}

.invalid-field-message {
  font-size: 0.8rem;
  color: $danger;
}
