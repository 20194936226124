@import "../../variables";

.help-text {
  margin-top: 0.25rem;
  padding: 0.25rem 0;
  font-size: 85%;
  color: $gray-600;

  p:last-child {
    margin-bottom: 0;
  }
}
